// Limits the file types that can be uploaded in ActionText
// https://blog.saeloun.com/2019/11/12/attachments-in-action-text-rails-6.html

window.addEventListener("trix-file-accept", function (event) {
  const acceptedTypes = ["image/jpg", "image/jpeg", "image/png", "image/gif"];
  if (!acceptedTypes.includes(event.file.type)) {
    event.preventDefault();
    alert("jpg,png,gif形式のみアップロード可能です");
  }
  const maxFileSize = 1024 * 1024 * 5; // 5MB
  if (event.file.size > maxFileSize) {
    event.preventDefault();
    alert("ファイルのサイズは5MBまでです。");
  }
});
